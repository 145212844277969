<template>
  <base-layout-two page-title="User" page-default-back-link="/users">
    <section class="ion-padding background-white">
      <ion-avatar>
        <img
          :src="
            user.profile_photo_url
              ? user.profile_photo_url
              : 'https://borym.s3.amazonaws.com/media/soft_green_light.png'
          "
        />
      </ion-avatar>
      <h1>{{ user.fullname }}</h1>
    </section>

    <base-card title="User Details">
      <h5>Title</h5>
      <p>{{ user.title }}</p>
      <hr />

      <h5>Slack</h5>
      <p>Slack Message</p>
      <hr />

      <h5>Email</h5>
      <p>{{ user.email }}</p>
      <hr />
    </base-card>
  </base-layout-two>
</template>

<script>
import { IonAvatar } from "@ionic/vue";

export default {
  components: { IonAvatar },

  data() {
    return {
      user: [],
    };
  },

  ionViewWillEnter() {
    this.fetchUser();
  },

  methods: {
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>